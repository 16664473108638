<template>
  <div class="Search">
    <h1 class="answers">
      {{ getChoice }}
    </h1>
  </div>
</template>

<script>
export default {
  computed: {
    name() {
      return this.$attrs.attributes.i18n || this.$attrs.name;
    },

    getChoice(choice) {
      const key = `QUESTION.${this.name}.CHOICE.${this.$attrs.answers}`;
      if (this.isNumber) {
        return this.$te(key) ? this.$t(key) : choice.toString();
      }
      return this.$t(key);
    },
  },
};
</script>

<style lang="scss" scoped>
  .Search{
    .answers{
      margin-left: 0.5rem;
    }
  }
</style>
