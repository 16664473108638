<template>
  <div class="changePassword">
    <div class="back">
      <font-awesome-icon
        :icon="['fas', 'arrow-left']"
        class="fontAwesome"
        @click="changeComponent('InfoUser')"
      />  {{ $t('PROFILE.PASSWORDCHANGE.BUTTON') }}
    </div>

    <div class="content">
      <div class="first-column">
        <div class="icon">
          <font-awesome-icon :icon="['fas', 'user-lock']" />
        </div>
      </div>

      <div>
        <div class="form">
          <p class="email">
            <font-awesome-icon
              :icon="['fas', 'envelope']"
              pull="right"
            />
            <span>{{ user.email }}</span>
          </p>

          <form
            @input="validation($event)"
            @keyup.enter="submit"
          >
            <div class="form-body">
              <FormInput
                v-for="(field, i) in fields"
                :key="i"
                v-model="field.value"
                v-bind="field"
              >
                <template #error>
                  <ValidationError
                    :errors="errors"
                    :name="field.name"
                  />
                </template>
              </FormInput>
            </div>

            <div class="form-footer">
              <BaseButton
                size="s"
                class="button"
                @click="submit"
              >
                {{ $t('PROFILE.PASSWORDCHANGE.BUTTON') }}
              </BaseButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ValidationError from '@/components/common/ValidationError';
import ValidationMixin from '@/mixins/ValidationMixin';
import FormInput from '@/components/profile/FormInput';

export default {
  components: {
    ValidationError,
    FormInput,
  },

  mixins: [ValidationMixin],

  props: {
    user:
    {
      type: Object,
      default: undefined,
    },
  },

  data() {
    return {
      fields: [
        {
          type: 'password',
          name: 'passwordActual',
          placeholder: '********',
          label: 'PROFILE.ACTUALPASSWORD.LABEL',
          error: this.$t('PROFILE.ERROR.PASSWORDACTUAL'),
          icon: ['fas', 'user-lock'],
          required: true,
          value: undefined,
        },
        {
          type: 'password',
          name: 'password',
          placeholder: '********',
          label: 'PROFILE.NEWPASSWORD.LABEL',
          error: this.$t('PROFILE.ERROR.NEWPASSWORD'),
          icon: ['fas', 'user-lock'],
          pattern: '.{8,}',
          required: true,
          value: undefined,
        },
        {
          type: 'password',
          name: 'passwordRepeat',
          placeholder: '*******',
          label: 'PROFILE.REPEATPASSWORD.LABEL',
          error: this.$t('PROFILE.ERROR.REPEATPASSWORD'),
          icon: ['fas', 'user-lock'],
          pattern: '.{8,}',
          required: true,
          value: undefined,
        },

      ],
    };
  },

  computed: {
    firstName() {
      return `${this.user.name}` ?? '--';
    },

  },

  methods: {
    async submit() {
      const currentForm = document.forms[0];
      this.checkAllFields(currentForm);

      const password = this.getField('password');
      const passwordRepeat = this.getField('passwordRepeat');

      if (this.areErrors()) {
        this.$toast.error(this.$t('FORM.MESSAGE.ERROR.CHECKFIELDS'));
      } else if (password !== passwordRepeat) {
        this.$toast.error(this.$t('FORM.MESSAGE.ERROR.PASSWORDSMATCH'));

        const reppasswordField = currentForm.querySelector('[name=passwordRepeat]');
        this.forceErrorElement(reppasswordField, this.$t('FORM.ERROR.REPEATPASSWORD'));
      } else {
        const formData = new FormData(currentForm);
        const data = this.fields.reduce((acc, field) => (Object.assign(acc, { [field.name]: formData.get(field.name) })), {});

        this.send(data);
      }
    },

    getField(name) {
      return this.fields.find(field => field.name === name)?.value;
    },

    async send(data) {
      this.$emit('change-password', data);
    },


    changeComponent(component) {
      this.$emit('change-component', component);
    },
  },
};
</script>

<style lang="scss" scoped>
.changePassword {
  @media #{$breakpoint-lg-max} {
    margin-top: 2.2rem;
    padding: 0;
  }
  .back {
    padding: 1rem;
    font-weight: 600;
    font-size: $font-size-l;
    text-transform: uppercase;
    color: $color-secondary;
    background: $color-neutral-light;

    .fontAwesome {
      color: $color-primary;
      cursor: pointer;
      font-size: $font-size-2xl;
    }
  }
  .content {
    display: flex;
    flex-wrap: wrap;

    .first-column {
      background: $color-primary-dark;
      padding: $spacing-xl;
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-wrap: wrap;
      flex: 1;

      .icon {
        font-size: 8rem;
        text-align: center;
      }

      @media #{$breakpoint-lg-max} {
        //height: 5rem;
        .icon {
          font-size: 3rem;
        }
      }
    }
    .title {
      font-size: $font-size-2xl;
      margin-bottom: $spacing-m;
      color: $color-primary;
    }

    .infoUser {
      background-color: #f4f4f4;
      // background-image: url('~@/assets/images/header-login.svg');
      background-position: center right -3rem;
      background-size: 55%;
      background-repeat: no-repeat;
      flex: 1 0 150px;
      height: 150px;
      position: relative;
      border-radius: 5px 5px 0 0;
    }

    .form {
      .email {
        background: $color-neutral-lightest;
        font-size: $font-size-xl;
        margin-bottom: $spacing-m;
        font-weight: 600;
        padding: $spacing-m;
        color: $color-primary-dark;
        font-size: $font-size-2xl;
        text-align: right;
      }
      form {
        margin: 0rem;
        padding: 2rem 3rem;
        border-radius: 0 0 5px 5px;
      }
    }
  }

  .BaseInput {
    ::v-deep {
      label {
        color: #111;
      }
      input {
        padding-left: $spacing-xl;
        margin-bottom: $spacing-l;
        border-bottom: solid 1px #ccc;
      }
    }
  }

  .form-footer {
    display: flex;
    margin: $spacing-s;

    .button {
      text-transform: uppercase;
      font-weight: 500;
      font-size: $font-size-s;
    }
  }
}
</style>
