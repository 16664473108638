<template>
  <div
    v-if="!isLoading"
    class="ReportView"
  >
    <div class="header">
      <TransitionSlide>
        <div class="text">
          <h1>{{ $t('RESULT.TITLE.REPORT') }}</h1>
          <BaseButton @click="print()">
            {{ $t('BUTTON.PRINT') }}
          </BaseButton>
        </div>
      </TransitionSlide>
    </div>

    <div class="content window-fit">
      <div class="head-title">
        <img
          class="image"
          :src="require(`@/assets/images/aztertu_completo.png`)"
          alt="logo"
        >
        <p v-if="nameProgram">
          {{ $t(`PROGRAM.${nameProgram}`) }}
        </p>
      </div>

      <div class="infoUser">
        <div>
          <p class="name">
            <span> {{ $t('LOGIN.NAME.LABEL') }} :</span> {{ userReport.user.firstName }} {{ userReport.user.lastName }}
          </p>

          <p class="title-name">
            <span> {{ $t('LOGIN.EMAIL.LABEL') }} :</span> {{ userReport.user.email }}
          </p>

          <div v-if="userReport.user.typeRegister === '1'">
            <p
              v-if="userReport.user.center !=='undefined'"
              class="title-name"
            >
              <span> {{ $t('QUESTION.REGISTER.CHOICE.1') }} :</span> {{ userReport.user.center.name }}
            </p>
            <p
              v-if="userReport.user.course!=='undefined'"
              class="title-name"
            >
              <span> {{ $t('FILTER.NAME.COURSE') }} :</span> {{ userReport.user.course.name }}
            </p>
          </div>

          <div v-if="userReport.user.typeRegister !=='1' && typeRegister!=='null'">
            <p
              v-if="userReport.user.typeRegister!=='null'"
              class="title-name"
            >
              <span> {{ $t(`QUESTION.REGISTER.CHOICE.${userReport.user.typeRegister}`) }} :</span> {{ nameType }}
            </p>
          </div>
        </div>
      </div>
      <div class="card">
        <TransitionFade
          v-for="block in blocks"
          :key="block.id"
        >
          <div class="block-title">
            <TransitionSlide side="left">
              <div class="head">
                <img
                  class="icon"
                  :src="require(`@/assets/iconos/${block.icon}`)"
                  alt="logo"
                >
                <p class="title">
                  {{ $t(`BLOCK.${block.title}`) }}
                </p>
              </div>
            </TransitionSlide>
            <ReportContainer
              v-for="answer in block.answers"
              :key="answer.questionId"
              :question="answer"
            />
          </div>
        </TransitionFade>
      </div>
      <TheFooter />
    </div>
  </div>
  <div v-else>
    <BaseSpinner />
  </div>
</template>

<script>
import { get } from 'vuex-pathify';

import ReportContainer from '@/components/reports/ReportContainer';
import TransitionFade from '@/transitions/TransitionFade';
import TransitionSlide from '@/transitions/TransitionSlide';
import TheFooter from '@/components/common/TheFooter';

export default {
  components: {
    ReportContainer,
    TransitionFade,
    TransitionSlide,
    TheFooter,
  },

  computed: {
    ...get('reportModule', [
      'isLoading',
      'getReport',
      'getBlocks',
      'getAnswers',
      'getProgram',
      'getDataUser',
    ]),

    blocks() {
      return this.getBlocks();
    },

    answers() {
      return this.getAnswers();
    },

    nameProgram() {
      const name = this.getProgram() ?? '';
      return name.toUpperCase();
    },

    userReport() {
      return this.getDataUser();
    },


  },

  async created() {
    const reportId = this.$route?.params?.reportId;
    if (reportId !== undefined) await this.$store.dispatch('reportModule/fetchReport', reportId);
    else this.$router.push({ name: 'Reports' });
  },

  methods: {
    print() {
      window.print();
    },
  },
};
</script>

<style lang="scss" scoped>
.ReportView {
  .header {
    padding-top: $theHeaderSpacing;
    background-image: url('~@/assets/images/f_home.jpg');
    background-size: cover;
    height: 220px;
    .text {
      margin: 3rem auto 3rem;
      max-width: 1200px;
      padding: 0 2rem 2rem;
      h1 {
        color: white;
        text-transform: uppercase;
        font-size: $font-size-3xl;
        font-weight: bold;
      }
    }

    @media #{$breakpoint-lg-max} {
      .text {
        margin: 2rem auto 3rem;
      }
    }
  }
  .content {
    max-width: 1200px;
    padding: 2rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-direction: row;
    flex-wrap: wrap;

    @media #{$breakpoint-lg-max} {
      margin: 0;
      padding: 0;
    }
    .head-title {
      display: flex;
      background: #fff;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      margin-bottom: $spacing-xl;

      padding: $spacing-m;
      color: $color-primary-dark;
      font-weight: 600;
      font-size: $font-size-3xl;
      box-shadow: $shadow-elevation-1;
      .image {
        width: 12rem;
      }
      p {
        text-align: center;
        flex: 1;
        padding: $spacing-s;
      }
    }
    .card {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
      width: 100%;
      gap: 20px;
      .block-title {
        // border: solid 1px $color-primary;
        box-shadow: $shadow-elevation-1;
        border-radius: 10px;
        page-break-inside: avoid !important;
        background: #fff;

        .head {
          color: $color-primary;
          border-bottom: 1px solid $color-primary;
          .icon {
            width: 3rem;
            position: absolute;
            margin-left: $spacing-m;
          }

          .title {
            margin-left: $spacing-3xl;
            padding: $spacing-s;
            font-size: $font-size-2xl;
            font-weight: 500;
          }
        }

        @media #{$breakpoint-lg-max} {
          width: 100%;
        }
      }
    }
     .infoUser {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        width: 100%;
        margin-bottom: 1rem;
        gap: 10px;
        > div {
          flex: 1;
          background: $color-neutral-lighter;
          box-shadow: $shadow-elevation-2;
          padding: $spacing-m;
        }
        .name {
          font-size: $font-size-xl;
          span {
            font-weight: 500;
            color: $color-primary;
          }
        }

        .title-name {
          font-size: $font-size-xl;
          span {
            font-weight: 500;
            color: $color-primary;
          }
        }
      }

    footer {
      margin-top: 1rem;
      box-shadow: none;
      width: 100%;
      display: none;

      ::v-deep {
        .footer {
          a { vertical-align: middle; }
          img {
            width: 12.5rem !important;
            height: auto !important;
          }
        }
      }
    }
  }

  @media #{$breakpoint-md-max} {
    .content {
      .card {
        grid-template-columns: repeat(1, 1fr);
      }
    }
    .subheader {
      display: none;
    }
  }
}

@media print {
  .ReportView {
    background: #fff;
    .header {
      display: none;
    }
    .content {
      .card {
        grid-template-columns: repeat(1, auto);
        .block-title {
          width: 100%;
        }
      }
      footer {
        display: flex;
      }
    }
  }
}
</style>
