import { render, staticRenderFns } from "./ReportContainer.vue?vue&type=template&id=3ea7acdd&scoped=true&"
import script from "./ReportContainer.vue?vue&type=script&lang=js&"
export * from "./ReportContainer.vue?vue&type=script&lang=js&"
import style0 from "./ReportContainer.vue?vue&type=style&index=0&id=3ea7acdd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ea7acdd",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/gestionetmultimedia/Documents/proyect_fronted/ortxadarfront/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3ea7acdd')) {
      api.createRecord('3ea7acdd', component.options)
    } else {
      api.reload('3ea7acdd', component.options)
    }
    module.hot.accept("./ReportContainer.vue?vue&type=template&id=3ea7acdd&scoped=true&", function () {
      api.rerender('3ea7acdd', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/reports/ReportContainer.vue"
export default component.exports