<template>
  <div class="QuestionFile">
    <div
      class="preview"
      :style="{backgroundImage:'url('+currentImage+')'}"
    />
  </div>
</template>

<script>
export default {
  props: {
    accept: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      file: null,
      preview: null,
    };
  },
  computed: {
    currentImage() {
      return this.$attrs?.answers?.route;
    },
  },

};
</script>

<style lang="scss" scoped>
  .QuestionFile{

    .preview{
      position: relative;
      --local-image-size: 30rem;
      padding: var(--spacing-m);
      height: var(--local-image-size);
      width: var(--local-image-size);
      border-radius: 5px;
      background-color: var(--color-neutral-mid-light);
      margin-bottom: var(--spacing-xs);
      background-size: cover;
       @media #{$breakpoint-md-max} {
          --local-image-size: 18rem;
       }
    }
  }
</style>
