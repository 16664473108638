var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Choices" }, [
    _c(
      "ul",
      _vm._l(_vm.choices, function(item) {
        return _c("li", { key: item }, [
          _vm._v(
            " " +
              _vm._s(_vm.$t("QUESTION." + _vm.name + ".CHOICE." + item)) +
              " "
          )
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }