var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isLoading
    ? _c("div", { staticClass: "ReportView" }, [
        _c(
          "div",
          { staticClass: "header" },
          [
            _c("TransitionSlide", [
              _c(
                "div",
                { staticClass: "text" },
                [
                  _c("h1", [_vm._v(_vm._s(_vm.$t("RESULT.TITLE.REPORT")))]),
                  _c(
                    "BaseButton",
                    {
                      on: {
                        click: function($event) {
                          return _vm.print()
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("BUTTON.PRINT")) + " ")]
                  )
                ],
                1
              )
            ])
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "content window-fit" },
          [
            _c("div", { staticClass: "head-title" }, [
              _c("img", {
                staticClass: "image",
                attrs: {
                  src: require("@/assets/images/aztertu_completo.png"),
                  alt: "logo"
                }
              }),
              _vm.nameProgram
                ? _c("p", [
                    _vm._v(
                      " " + _vm._s(_vm.$t("PROGRAM." + _vm.nameProgram)) + " "
                    )
                  ])
                : _vm._e()
            ]),
            _c("div", { staticClass: "infoUser" }, [
              _c("div", [
                _c("p", { staticClass: "name" }, [
                  _c("span", [
                    _vm._v(" " + _vm._s(_vm.$t("LOGIN.NAME.LABEL")) + " :")
                  ]),
                  _vm._v(
                    " " +
                      _vm._s(_vm.userReport.user.firstName) +
                      " " +
                      _vm._s(_vm.userReport.user.lastName) +
                      " "
                  )
                ]),
                _c("p", { staticClass: "title-name" }, [
                  _c("span", [
                    _vm._v(" " + _vm._s(_vm.$t("LOGIN.EMAIL.LABEL")) + " :")
                  ]),
                  _vm._v(" " + _vm._s(_vm.userReport.user.email) + " ")
                ]),
                _vm.userReport.user.typeRegister === "1"
                  ? _c("div", [
                      _vm.userReport.user.center !== "undefined"
                        ? _c("p", { staticClass: "title-name" }, [
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("QUESTION.REGISTER.CHOICE.1")) +
                                  " :"
                              )
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(_vm.userReport.user.center.name) +
                                " "
                            )
                          ])
                        : _vm._e(),
                      _vm.userReport.user.course !== "undefined"
                        ? _c("p", { staticClass: "title-name" }, [
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("FILTER.NAME.COURSE")) +
                                  " :"
                              )
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(_vm.userReport.user.course.name) +
                                " "
                            )
                          ])
                        : _vm._e()
                    ])
                  : _vm._e(),
                _vm.userReport.user.typeRegister !== "1" &&
                _vm.typeRegister !== "null"
                  ? _c("div", [
                      _vm.userReport.user.typeRegister !== "null"
                        ? _c("p", { staticClass: "title-name" }, [
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "QUESTION.REGISTER.CHOICE." +
                                        _vm.userReport.user.typeRegister
                                    )
                                  ) +
                                  " :"
                              )
                            ]),
                            _vm._v(" " + _vm._s(_vm.nameType) + " ")
                          ])
                        : _vm._e()
                    ])
                  : _vm._e()
              ])
            ]),
            _c(
              "div",
              { staticClass: "card" },
              _vm._l(_vm.blocks, function(block) {
                return _c("TransitionFade", { key: block.id }, [
                  _c(
                    "div",
                    { staticClass: "block-title" },
                    [
                      _c("TransitionSlide", { attrs: { side: "left" } }, [
                        _c("div", { staticClass: "head" }, [
                          _c("img", {
                            staticClass: "icon",
                            attrs: {
                              src: require("@/assets/iconos/" + block.icon),
                              alt: "logo"
                            }
                          }),
                          _c("p", { staticClass: "title" }, [
                            _vm._v(
                              " " + _vm._s(_vm.$t("BLOCK." + block.title)) + " "
                            )
                          ])
                        ])
                      ]),
                      _vm._l(block.answers, function(answer) {
                        return _c("ReportContainer", {
                          key: answer.questionId,
                          attrs: { question: answer }
                        })
                      })
                    ],
                    2
                  )
                ])
              }),
              1
            ),
            _c("TheFooter")
          ],
          1
        )
      ])
    : _c("div", [_c("BaseSpinner")], 1)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }