var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "PaneAdmin" },
    [
      _c("EmptySubHeader", { staticClass: "subheader" }),
      !_vm.isLoading
        ? _c(
            "div",
            { staticClass: "content" },
            [
              _c("TransitionFade", [
                _vm.infoUser
                  ? _c("div", { staticClass: "head" }, [
                      _c("div", [
                        _vm.infoUser && _vm.infoUser.avatar !== ""
                          ? _c("div", {
                              staticClass: "avatar",
                              style: {
                                backgroundImage: "url(" + _vm.avatar + ")"
                              }
                            })
                          : _c("div", { staticClass: "avatar" })
                      ]),
                      _c("div", { staticClass: "info" }, [
                        _c("p", { staticClass: "title" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("TITLE.STATS.REPORTS")) + " "
                          )
                        ]),
                        _c("div", { staticClass: "card" }, [
                          _c("div", [
                            _c("p", { staticClass: "name" }, [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("LOGIN.NAME.LABEL")) +
                                    " :"
                                )
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.firstName) +
                                  " " +
                                  _vm._s(_vm.lastName) +
                                  " "
                              )
                            ]),
                            _c("p", { staticClass: "title-name" }, [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("LOGIN.EMAIL.LABEL")) +
                                    " :"
                                )
                              ]),
                              _vm._v(" " + _vm._s(_vm.infoUser.email) + " ")
                            ]),
                            _vm.typeRegister === "1"
                              ? _c("div", [
                                  _vm.center !== "undefined"
                                    ? _c("p", { staticClass: "title-name" }, [
                                        _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "QUESTION.REGISTER.CHOICE.1"
                                                )
                                              ) +
                                              " :"
                                          )
                                        ]),
                                        _vm._v(" " + _vm._s(_vm.center) + " ")
                                      ])
                                    : _vm._e(),
                                  _vm.course !== "undefined"
                                    ? _c("p", { staticClass: "title-name" }, [
                                        _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("FILTER.NAME.COURSE")
                                              ) +
                                              " :"
                                          )
                                        ]),
                                        _vm._v(" " + _vm._s(_vm.course) + " ")
                                      ])
                                    : _vm._e()
                                ])
                              : _vm._e(),
                            _vm.typeRegister !== "1" &&
                            _vm.typeRegister !== "null"
                              ? _c("div", [
                                  _vm.typeRegister !== "null"
                                    ? _c("p", { staticClass: "title-name" }, [
                                        _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "QUESTION.REGISTER.CHOICE." +
                                                    _vm.typeRegister
                                                )
                                              ) +
                                              " :"
                                          )
                                        ]),
                                        _vm._v(" " + _vm._s(_vm.nameType) + " ")
                                      ])
                                    : _vm._e()
                                ])
                              : _vm._e()
                          ])
                        ]),
                        _c(
                          "div",
                          { staticClass: "filter" },
                          [
                            _c("p", [
                              _vm._v(_vm._s(_vm.$t("FILTER.PROGRAMS")))
                            ]),
                            _c(
                              "BaseSelect",
                              _vm._b(
                                {
                                  model: {
                                    value: _vm.search,
                                    callback: function($$v) {
                                      _vm.search = $$v
                                    },
                                    expression: "search"
                                  }
                                },
                                "BaseSelect",
                                _vm.questionAttributes,
                                false
                              )
                            )
                          ],
                          1
                        )
                      ])
                    ])
                  : _vm._e()
              ]),
              _c(
                "div",
                { staticClass: "report" },
                [_c("TableReportUser", { attrs: { items: _vm.filterReport } })],
                1
              )
            ],
            1
          )
        : _c("div", [_c("BaseSpinner")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }