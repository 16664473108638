<template>
  <div class="ReportContainer">
    <!--   <label
      v-if="!isStatement && hasStatementLabel"
      class="title"
      :for="question.key"
      v-html="statementLabel"
    /> -->
    <p>
      <label
        v-if="!isStatement && hasStatementLabel"
        class="title"
        :for="question.key"
        v-html="statementLabelAnswers"
      />
    </p>

    <component
      :is="questionType"
      class="component"
      v-bind="questionAttributes"
    />
  </div>
</template>

<script>
import Choice from '@/components/reports/Choice';
import ChoiceCustom from '@/components/reports/ChoiceCustom';
import Search from '@/components/reports/Search';
import Choices from '@/components/reports/Choices';
import Input from '@/components/reports/Input';
import Map from '@/components/reports/Map';
import QuestionFile from '@/components/reports/QuestionFile';

const QUESTIONS_TYPES = {
  choice: 'Choice',
  search: 'Search',
  choiceCustom: 'ChoiceCustom',
  choices: 'Choices',
  input: 'Input',
  map: 'Map',
  uploadFile: 'QuestionFile',
  box: 'Choice',
  text: 'Input',

};

export default {
  components: {
    Choice,
    ChoiceCustom,
    Search,
    Choices,
    Input,
    Map,
    QuestionFile,
  },

  props: {
    question: {
      type: Object,
      required: true,
    },
  },

  computed: {
    hasStatementLabel() {
      return true;
    },

    isStatement() {
      return this.question.type === 'statement';
    },

    statementLabel() {
      const i18n = this.question.attributes?.i18n || this.question.key;
      return this.$te(`QUESTION.${i18n}.LABEL`) ? this.$t(`QUESTION.${i18n}.LABEL`) : this.$t(`QUESTION.${i18n}.LABEL`);
    },

    statementLabelAnswers() {
      const i18n = this.question.attributes?.i18n || this.question.key;
      return this.$te(`ANSWERS.${i18n}.LABEL`) ? this.$t(`ANSWERS.${i18n}.LABEL`) : this.$t(`QUESTION.${i18n}.LABEL`);
    },

    questionType() {
      return (typeof QUESTIONS_TYPES[this.question.type] !== 'undefined') ?
        QUESTIONS_TYPES[this.question.type] :
        QUESTIONS_TYPES.input;
    },

    questionAttributes() {
      return Object.assign({}, {
        type: this.question.type, name: this.question.key, answers: this.question.answer, attributes: this.question.attributes,
      });
    },
  },

};
</script>

<style lang="scss" scoped>
.ReportContainer {
  margin: $spacing-m $spacing-xl;
  .title {
    font-size: $font-size-l;
    color: $color-primary-dark;
  }

}
</style>
