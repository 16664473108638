<template>
  <div class="changePassword">
    <div class="back">
      <font-awesome-icon
        :icon="['fas', 'arrow-left']"
        class="fontAwesome"
        @click="changeComponent('InfoUser')"
      />   {{ $t('PROFILE.PASSWORDCHANGE.BUTTON') }}
    </div>

    <div class="content">
      <div class="first-column">
        <div class="icon">
          <font-awesome-icon :icon="['fas', 'image']" />
        </div>
      </div>

      <div class="form">
        <p class="email">
          <font-awesome-icon
            :icon="['fas', 'envelope']"
            pull="right"
          />
          <span>{{ user.email }}</span>
        </p>

        <aside>
          <div
            v-if="currentImage"
            class="avatar"
            :style="{backgroundImage:'url('+currentImage+')'}"
          />
          <div
            v-else
            class="avatar"
          />

          <BaseInputFile
            tag="image"
            accept="image/*"
            @change.native="loadImage($event)"
          />

          <div class="boxButtonAvatar">
            <BaseButton
              size="s"
              @click="changeAvatar()"
            >
              {{ $t('BUTTON.SAVE') }}
            </BaseButton>
          </div>
        </aside>
      </div>
    </div>
  </div>
</template>

<script>

import ValidationMixin from '@/mixins/ValidationMixin';


export default {


  mixins: [ValidationMixin],

  props: {
    user:
    {
      type: Object,
      default: undefined,
    },
  },

  data() {
    return {
      image: null,
      preview: null,
    };
  },

  computed: {
    firstName() {
      return `${this.user.name}` ?? '--';
    },
    currentImage() {
      return this.image ? this.preview : `uploads/users/avatar/${this.user?.avatar}`;
    },
  },

  methods: {
    loadImage(event) {
      const input = event.target;

      if (input.files && input.files[0]) {
        const reader = new FileReader();

        reader.onload = (e) => {
          this.preview = e.target.result;
        };

        this.image = input.files[0];

        reader.readAsDataURL(input.files[0]);
      }
    },

    changeComponent() {
      this.$emit('change-component', 'InfoUser');
    },

    changeAvatar() {
      if (this.image != null) {
        this.$emit('change-avatar', this.image);
      }
    },

  },
};
</script>

<style lang="scss" scoped>
.changePassword {
  @media #{$breakpoint-lg-max} {
    margin-top: 2.2rem;
    padding: 0;
  }
  .back {
    padding: 1rem;
    font-weight: 600;
    font-size: $font-size-l;
    text-transform: uppercase;
    color: $color-secondary;
    background: $color-neutral-light;

    .fontAwesome {
      color: $color-primary;
      cursor: pointer;
      font-size: $font-size-2xl;
    }
  }
  .content {
    display: flex;
    flex-wrap: wrap;

    .first-column {
      background: $color-primary-dark;
      padding: $spacing-xl;
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-wrap: wrap;
      flex: 1 ;
      min-width: 250px;

      .icon {
        font-size: 8rem;
        text-align: center;
      }

      @media #{$breakpoint-lg-max} {
        height: 5rem;
        .icon {
          font-size: 3rem;
        }
      }
    }
    .title {
      font-size: $font-size-2xl;
      margin-bottom: $spacing-m;
      color: $color-primary;
    }

    .infoUser {
      background-color: #f4f4f4;
      background-position: center right -3rem;
      background-size: 55%;
      background-repeat: no-repeat;
      flex: 1 0 150px;
      height: 150px;
      position: relative;
      border-radius: 5px 5px 0 0;
    }

    .form {
      .email {
        background: $color-neutral-lightest;
        font-size: $font-size-xl;
        margin-bottom: $spacing-m;
        font-weight: 600;
        padding: $spacing-m;
        color: $color-primary-dark;
        font-size: $font-size-2xl;
        text-align: right;
      }

      aside {
        background-color: var(--color-secondary);
        display: grid;
        align-content: center;
        gap: var(--spacing-xs);
        justify-content: center;
        justify-items: center;
        padding: var(--spacing-s);

        .avatar {
          position: relative;
          --local-image-size: 20rem;
          padding: var(--spacing-m);
          height: var(--local-image-size);
          width: var(--local-image-size);
          border-radius: 50%;
          background-color: var(--color-neutral-mid-light);
          margin-bottom: var(--spacing-xs);
          background-size: cover;
          background-image: url('~@/assets/images/profile-user.png');
          margin: $spacing-xl;
          border: solid 1px $color-neutral-dark;
        }
        .boxButtonAvatar {
          margin-bottom: 2rem;
        }
      }

      @media #{$breakpoint-lg-max} {
        width: 100%;
        aside {
          .avatar {
            --local-image-size: 14rem;
          }
        }
      }
    }
  }
}
</style>
