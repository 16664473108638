<template>
  <div class="PaneAdmin">
    <EmptySubHeader class="subheader" />

    <div
      v-if="!isLoading"
      class="content"
    >
      <TransitionFade>
        <div
          v-if="infoUser"
          class="head"
        >
          <div>
            <div
              v-if="infoUser && infoUser.avatar !== ''"
              class="avatar"
              :style="{backgroundImage:'url('+avatar+')'}"
            />
            <div
              v-else
              class="avatar"
            />
          </div>

          <div class="info">
            <p class="title">
              {{ $t('TITLE.STATS.REPORTS') }}
            </p>
            <div class="card">
              <div>
                <p class="name">
                  <span> {{ $t('LOGIN.NAME.LABEL') }} :</span> {{ firstName }} {{ lastName }}
                </p>

                <p class="title-name">
                  <span> {{ $t('LOGIN.EMAIL.LABEL') }} :</span> {{ infoUser.email }}
                </p>

                <div v-if="typeRegister === '1'">
                  <p
                    v-if="center!=='undefined'"
                    class="title-name"
                  >
                    <span> {{ $t('QUESTION.REGISTER.CHOICE.1') }} :</span> {{ center }}
                  </p>
                  <p
                    v-if="course!=='undefined'"
                    class="title-name"
                  >
                    <span> {{ $t('FILTER.NAME.COURSE') }} :</span> {{ course }}
                  </p>
                </div>

                <div v-if="typeRegister!=='1' && typeRegister!=='null'">
                  <p
                    v-if="typeRegister!=='null'"
                    class="title-name"
                  >
                    <span> {{ $t(`QUESTION.REGISTER.CHOICE.${typeRegister}`) }} :</span> {{ nameType }}
                  </p>
                </div>
              </div>
            </div>

            <div class="filter">
              <p>{{ $t('FILTER.PROGRAMS') }}</p>
              <BaseSelect
                v-model="search"
                v-bind="questionAttributes"
              />
            </div>
          </div>
        </div>
      </TransitionFade>

      <div class="report">
        <TableReportUser :items="filterReport" />
      </div>
    </div>
    <div v-else>
      <BaseSpinner />
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';

import EmptySubHeader from '@/components/subheader/EmptySubHeader';
import TransitionFade from '@/transitions/TransitionFade';
import TableReportUser from '@/components/panel-admin/TableReportUser';


export default {
  components: {
    EmptySubHeader,
    TransitionFade,
    TableReportUser,
  },

  data() {
    return {
      itemsSearch:
      {
        question: 'PROGRAM',
        choices: [1, 2, 3, 4],
      },
      search: '',
    };
  },


  computed: {
    ...get('authModule', ['getUser']),
    ...get('adminModule', ['isLoading', 'getInfoUser', 'getReports', 'getFilterProgram']),

    user() {
      return this.getUser();
    },

    infoUser() {
      return this.getInfoUser();
    },

    userId() {
      return this.$route?.params.userId;
    },

    firstName() {
      return `${this.infoUser.firstName}` ?? '--';
    },

    lastName() {
      return `${this.infoUser.lastName}` ?? '--';
    },

    avatar() {
      return `/uploads/users/avatar/${this.infoUser?.avatar}`;
    },

    filterProgram() {
      return this.getFilterProgram(this.search);
    },

    reportsUser() {
      return this.getReports();
    },

    filterReport() {
      if (this.search !== '') {
        return this.filterProgram;
      }
      return this.reportsUser;
    },

    center() {
      return `${this.infoUser.center?.name}` ?? '--';
    },

    course() {
      return `${this.infoUser.course?.name}` ?? '--';
    },

    typeRegister() {
      return `${this.infoUser.typeRegister}` ?? '--';
    },
    nameType() {
      return `${this.infoUser.nameType}` ?? '--';
    },

    questionAttributes() {
      return Object.assign({}, this.itemsSearch, { name: 'PROGRAM', disabled: this.isDisabled });
    },
  },

  async created() {
    await this.$store.dispatch('adminModule/fetchInfoUser', this.userId);
    await this.$store.dispatch('adminModule/fetchReportUser', this.userId);
  },

  methods: {
    goToCourse() {
      return this.$router.push({ name: 'Courses' });
    },

    goToCenter() {
      return this.$router.push({ name: 'Centers' });
    },
  },
};
</script>

<style lang="scss" scoped>
.PaneAdmin {
  .content {
    max-width: 1200px;
    padding: 2rem;
    display: flex;

    flex-direction: column;
    justify-content: center;
    margin: auto;

    .head {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      background: #fff;
      padding: $spacing-s;
      width: 100%;
      box-shadow: $shadow-elevation-1;
      gap: 30px;

      h1 {
        text-align: center;
        font-size: $font-size-2xl;
        font-weight: 600;
      }

      .avatar {
        position: relative;
        --local-image-size: 14rem;
        padding: var(--spacing-m);
        height: var(--local-image-size);
        width: var(--local-image-size);
        border-radius: 50%;
        background-color: var(--color-neutral-mid-light);
        background-image: url('~@/assets/images/profile-user.png');
        margin-bottom: var(--spacing-xs);
        background-size: cover;
        margin: auto;
      }
    }

    .info {
      margin: 1rem;
      flex: 1;

      .title {
        font-size: $font-size-3xl;
        text-align: center;
        margin-bottom: $spacing-m;
        color: $color-primary;
        font-weight: 550;
        background: $color-neutral-lighter;
        box-shadow: $shadow-elevation-2;
        padding: $spacing-s;
      }
      .card {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 10px;
        > div {
          flex: 1;
          background: $color-neutral-lighter;
          box-shadow: $shadow-elevation-2;
          padding: $spacing-m;
        }
        .name {
          font-size: $font-size-xl;
          span {
            font-weight: 500;
            color: $color-primary;
          }
        }

        .title-name {
          font-size: $font-size-xl;
          span {
            font-weight: 500;
            color: $color-primary;
          }
        }
      }

      .filter {
        padding-top: $spacing-l;
      }
    }

    .report {
      margin-top: $spacing-l;
    }

    @media #{$breakpoint-lg-max} {
      margin: 0;
      padding: 0;
    }
  }

  @media #{$breakpoint-lg-max} {
    .subheader {
      display: none;
    }
    margin-top: 5rem;
  }
}
</style>
