<template>
  <div class="Choice">
    <h1 class="answers">
      {{ getChoice }}
    </h1>
  </div>
</template>

<script>
export default {

  computed: {
    getChoice(choice) {
      const key = `QUESTION.${this.name}.CHOICE.${this.$attrs.answers}`;
      if (this.isNumber) {
        return this.$te(key) ? this.$t(key) : choice.toString();
      }
      return this.$t(key);
    },

    name() {
      return this.$attrs.attributes.i18n || this.$attrs.name;
    }
  },
};
</script>

<style lang="scss" scoped>
  .Choice{
   background: $color-neutral-lightest;
    padding: $spacing-m;
    .answers{
      margin-left: 0.5rem;
    }

  }
</style>
