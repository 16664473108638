<template>
  <div class="changePassword">
    <!--   <div class="back">
      <font-awesome-icon :icon="['fas', 'arrow-left']" /> Cambiar contraseña
    </div> -->

    <div class="content">
      <div class="first-column">
        <!-- <div class="icon">
          <font-awesome-icon :icon="['fas', 'user-edit']" />
        </div> -->
        <div
          class="avatar"
          :style="{backgroundImage:'url('+avatar+')'}"
        />
        <div class="button">
          <BaseButton
            variation="secondary"
            @click="changeComponent('ChangeAvatar')"
          >
            {{ $t('PROFILE.CHANGEAVATAR.BUTTON') }}
          </BaseButton>
        </div>
      </div>

      <div class="second-column">
        <div class="change-password">
          <p>{{ $t('PROFILE.PASSWORDCHANGE.QUESTION') }}</p>
          <div class="action">
            <div class="icon">
              <font-awesome-icon :icon="['fas', 'user-lock']" />
              *******
            </div>

            <BaseButton
              class="button"
              size="s"
              variation="secondary"
              theme="light"
              @click="changeComponent('ChangePassword')"
            >
              {{ $t('PROFILE.PASSWORDCHANGE.BUTTON') }}
            </BaseButton>
          </div>
        </div>

        <div class="email">
          <p>
            <font-awesome-icon
              :icon="['fas', 'envelope']"
              pull="right"
            />
            <span>{{ user.email }}</span>
          </p>

          <p>
            <font-awesome-icon
              :icon="['fas', 'user']"
              pull="right"
            />
            <span>{{ firstName }} {{ lastName }}</span>
          </p>
        </div>

        <div class="form">
          <form
            v-if="user"
            @input="validation($event)"
            @keyup.enter="submit"
          >
            <div class="form-body">
              <FormInput
                v-for="(field, i) in fields"
                :key="i"
                v-model="field.value"
                v-bind="field"
              >
                <template #error>
                  <ValidationError
                    :errors="errors"
                    :name="field.name"
                  />
                </template>
              </FormInput>
            </div>

            <div class="form-footer">
              <BaseButton
                size="s"
                class="button"
                @click="submit"
              >
                {{ $t('BUTTON.SAVE') }}
              </BaseButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ValidationError from '@/components/common/ValidationError';
import ValidationMixin from '@/mixins/ValidationMixin';
import FormInput from '@/components/profile/FormInput';

export default {
  components: {
    ValidationError,
    FormInput,
  },

  mixins: [ValidationMixin],

  props: {
    user:
    {
      type: Object,
      default: undefined,
    },
  },

  data() {
    return {
      fields: [
        {
          type: 'text',
          name: 'firstName',
          placeholder: this.$t('PROFILE.NAME.PLACEHOLDER'),
          label: 'PROFILE.NAME.LABEL',
          error: this.$t('PROFILE.ERROR.NAME'),
          icon: ['fas', 'user-edit'],
          required: true,
          value: `${this.user.name}`,
        },
        {
          type: 'text',
          name: 'lastName',
          placeholder: this.$t('PROFILE.LASTNAME.PLACEHOLDER'),
          label: 'PROFILE.LASTNAME.LABEL',
          error: this.$t('PROFILE.ERROR.LASTNAME'),
          icon: ['fas', 'user-edit'],
          required: true,
          value: `${this.user.last_name}`,
        },
      ],

      profile: {
        firstName: undefined,
        lastName: undefined,

      },
    };
  },

  computed: {
    firstName() {
      return `${this.user.name}` ?? '--';
    },

    lastName() {
      return `${this.user.last_name}` ?? '--';
    },

    avatar() {
      if (this.user?.avatar) {
        return `uploads/users/avatar/${this.user?.avatar}`;
      }
      /* eslint-disable */
      return require('@/assets/images/profile-user.png');
    },
  },

  created() {
    this.profile = {
      firstName: this.firstName,
      lastName: this.lastName,
    };
  },

  methods: {
    async submit() {
      const currentForm = document.forms[0];
      this.checkAllFields(currentForm);

      if (this.areErrors()) {
        this.$toast.error(this.$t('FORM.MESSAGE.ERROR.CHECKFIELDS'));
      } else {
        const formData = new FormData(currentForm);
        const data = this.fields.reduce((acc, field) => (Object.assign(acc, { [field.name]: formData.get(field.name) })), {});


        this.send(data);
      }
    },

    getField(name) {
      return this.fields.find(field => field.name === name)?.value;
    },

    async send(data) {
      this.$emit('change-information-user', data);
    },

    changeComponent(component) {
      this.$emit('change-component', component);
    },
  },
};
</script>

<style lang="scss" scoped>
.changePassword {
  @media #{$breakpoint-lg-max} {
    margin-top: 2.2rem;
    padding: 0;
  }
  .back {
    height: 3rem;
    padding: 1rem;
    font-weight: 600;
    font-size: $font-size-l;
    text-transform: uppercase;
    color: $color-secondary;
    background: $color-neutral-light;
  }
  .content {
    display: flex;
    flex-wrap: wrap;

    .first-column {
      background: $color-primary-dark;
      padding: $spacing-xl;
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-wrap: wrap;
      flex: 1;
      align-items: center;
      .icon {
        font-size: 8rem;
        text-align: center;
      }
      .button{
        margin:  2rem auto;
      }

      .avatar{
      position: relative;
      --local-image-size: 18rem;
      padding: var(--spacing-m);
      height: var(--local-image-size);
      width: var(--local-image-size);
      border-radius: 50%;
      background-color: var(--color-neutral-mid-light);
      margin-bottom: var(--spacing-xs);
      background-size: cover;
    }

      @media #{$breakpoint-lg-max} {
        //height: 12rem;
        .icon {
          font-size: 3rem;
        }
        .avatar{
          --local-image-size: 8rem;
        }
      }
    }

    .second-column {
      background: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-wrap: wrap;
      flex: 5;

      .change-password{
        background: $color-neutral-dark;
        color: #fff;
        text-align: center;
        padding:$spacing-m;
        font-weight: 500;
        .action{
          .icon{
            font-size: $font-size-2xl;
            margin-bottom: $spacing-m;
          }
          .button{
            width: 100%;
          }
        }
      }

      .email {
        background: $color-neutral-lightest;
        font-size: $font-size-xl;
        margin-bottom: $spacing-m;
        font-weight: 450;
        padding: $spacing-m;

        p:first-child {
          color: $color-primary-dark;
          font-size: $font-size-2xl;
          text-align: right;
        }
        p:nth-child(2) {
          font-size: $font-size-m;
          text-align: right;
        }
      }


      .form {
        margin: 0rem;
        padding: 2rem 3rem;
        border-radius: 0 0 5px 5px;
      }
    }
  }

  .BaseInput {
    ::v-deep {
      label {
        color: #111;
      }
      input {
        padding-left: $spacing-xl;
        margin-bottom: $spacing-l;
        border-bottom: solid 1px #ccc;
      }
    }
  }

  .form-footer {
    display: flex;
    margin: $spacing-s;

    .button {
      text-transform: uppercase;
      font-weight: 500;
      font-size: $font-size-s;
    }
  }
}
</style>
