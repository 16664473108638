import { render, staticRenderFns } from "./QuestionFile.vue?vue&type=template&id=42821402&scoped=true&"
import script from "./QuestionFile.vue?vue&type=script&lang=js&"
export * from "./QuestionFile.vue?vue&type=script&lang=js&"
import style0 from "./QuestionFile.vue?vue&type=style&index=0&id=42821402&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42821402",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/gestionetmultimedia/Documents/proyect_fronted/ortxadarfront/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('42821402')) {
      api.createRecord('42821402', component.options)
    } else {
      api.reload('42821402', component.options)
    }
    module.hot.accept("./QuestionFile.vue?vue&type=template&id=42821402&scoped=true&", function () {
      api.rerender('42821402', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/reports/QuestionFile.vue"
export default component.exports