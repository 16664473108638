var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "changePassword" }, [
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "first-column" }, [
        _c("div", {
          staticClass: "avatar",
          style: { backgroundImage: "url(" + _vm.avatar + ")" }
        }),
        _c(
          "div",
          { staticClass: "button" },
          [
            _c(
              "BaseButton",
              {
                attrs: { variation: "secondary" },
                on: {
                  click: function($event) {
                    return _vm.changeComponent("ChangeAvatar")
                  }
                }
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("PROFILE.CHANGEAVATAR.BUTTON")) + " "
                )
              ]
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "second-column" }, [
        _c("div", { staticClass: "change-password" }, [
          _c("p", [_vm._v(_vm._s(_vm.$t("PROFILE.PASSWORDCHANGE.QUESTION")))]),
          _c(
            "div",
            { staticClass: "action" },
            [
              _c(
                "div",
                { staticClass: "icon" },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["fas", "user-lock"] }
                  }),
                  _vm._v(" ******* ")
                ],
                1
              ),
              _c(
                "BaseButton",
                {
                  staticClass: "button",
                  attrs: { size: "s", variation: "secondary", theme: "light" },
                  on: {
                    click: function($event) {
                      return _vm.changeComponent("ChangePassword")
                    }
                  }
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("PROFILE.PASSWORDCHANGE.BUTTON")) + " "
                  )
                ]
              )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "email" }, [
          _c(
            "p",
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["fas", "envelope"], pull: "right" }
              }),
              _c("span", [_vm._v(_vm._s(_vm.user.email))])
            ],
            1
          ),
          _c(
            "p",
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["fas", "user"], pull: "right" }
              }),
              _c("span", [
                _vm._v(_vm._s(_vm.firstName) + " " + _vm._s(_vm.lastName))
              ])
            ],
            1
          )
        ]),
        _c("div", { staticClass: "form" }, [
          _vm.user
            ? _c(
                "form",
                {
                  on: {
                    input: function($event) {
                      return _vm.validation($event)
                    },
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.submit.apply(null, arguments)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "form-body" },
                    _vm._l(_vm.fields, function(field, i) {
                      return _c(
                        "FormInput",
                        _vm._b(
                          {
                            key: i,
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "error",
                                  fn: function() {
                                    return [
                                      _c("ValidationError", {
                                        attrs: {
                                          errors: _vm.errors,
                                          name: field.name
                                        }
                                      })
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: field.value,
                              callback: function($$v) {
                                _vm.$set(field, "value", $$v)
                              },
                              expression: "field.value"
                            }
                          },
                          "FormInput",
                          field,
                          false
                        )
                      )
                    }),
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-footer" },
                    [
                      _c(
                        "BaseButton",
                        {
                          staticClass: "button",
                          attrs: { size: "s" },
                          on: { click: _vm.submit }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("BUTTON.SAVE")) + " ")]
                      )
                    ],
                    1
                  )
                ]
              )
            : _vm._e()
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }