var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ReportContainer" },
    [
      _c("p", [
        !_vm.isStatement && _vm.hasStatementLabel
          ? _c("label", {
              staticClass: "title",
              attrs: { for: _vm.question.key },
              domProps: { innerHTML: _vm._s(_vm.statementLabelAnswers) }
            })
          : _vm._e()
      ]),
      _c(
        _vm.questionType,
        _vm._b(
          { tag: "component", staticClass: "component" },
          "component",
          _vm.questionAttributes,
          false
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }