<template>
  <div class="Map">
    <div
      v-if="myCoordinates"
      class="my-ubication"
    >
      <p for="Latitud">
        {{ $t('LATITUDE') }} : {{ myCoordinates.lat }}
      </p>
      <p for="Longitud">
        {{ $t('LENGTH') }}  :  {{ myCoordinates.lng }}
      </p>
    </div>

    <slot name="error" />

    <GmapMap
      ref="mapRef"
      :center="centerMap"
      :zoom="zoom"
      map-type-id="satellite"
      style="width: 100%; height: 350px"
    >
      <GmapMarker
        :position="myCoordinates"
        :clickable="true"
        :draggable="true"
        @click="center=myCoordinates"
      />
    </GmapMap>
  </div>
</template>

<script>

export default {

  props: {
    value: {
      type: Object,
      default: undefined,
    },
  },

  data() {
    return {
      map: null,
      myCoordinates: null,
      zoom: 12,
      access: 0,
      markers: [],
    };
  },

  computed: {

    centerMap() {
      if (!this.myCoordinates) {
        return {
          lat: 43.3530573,
          lng: -2.8405813,
        };
      }

      return {
        lat: this.$attrs.answers?.lat,
        lng: this.$attrs.answers?.lng,
      };
    },


    mapCoordinates() {
      if (!this.map) {
        return {
          lat: 0,
          lng: 0,
        };
      }
      return {
        lat: this.map.getCenter().lat(),
        lng: this.map.getCenter().lng(),
      };
    },
  },

  mounted() {
    // this.$refs.mapRef.$mapPromise.then(map => this.map === map);

    const position = {
      lat: this.$attrs.answers?.lat,
      lng: this.$attrs.answers?.lng,
    };
    this.myCoordinates = position;
  },


};
</script>

<style lang="scss" scoped>
.Map {
  .access {
    margin-bottom: $spacing-m;
    .button {
      width: 100%;
    }
    .image {
      width: 1.5rem;
    }
  }

  .inputComplete {
    width: 100%;
    height: 2.5rem;
    border-radius: 5px;
    border: solid 1px $color-primary;
    margin-bottom: $spacing-m;
    padding: $spacing-m;
  }

  .my-ubication {
    margin-bottom: $spacing-s;
    background-color: #fff;
    padding: $spacing-s;
  }
}
</style>
