var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Input" }, [
    _vm.units
      ? _c("h1", { staticClass: "unidades" }, [
          _c("span", { staticClass: "units" }, [
            _vm._v(
              " " + _vm._s(_vm.$attrs.answers) + " " + _vm._s(_vm.units) + " "
            )
          ])
        ])
      : _vm._e(),
    _vm.$attrs.type === "valorationChoices"
      ? _c("h1", [
          _vm.$attrs.attributes.images
            ? _c("span", { staticClass: "valoration" }, [
                _c("img", {
                  staticClass: "image",
                  attrs: {
                    src: _vm.getImage(_vm.$attrs.answers - 1),
                    alt: "logo"
                  }
                }),
                _c(
                  "p",
                  { staticClass: "star" },
                  _vm._l(_vm.start(_vm.$attrs.answers), function(star) {
                    return _c("font-awesome-icon", {
                      key: star,
                      attrs: { icon: ["fas", "star"] }
                    })
                  }),
                  1
                )
              ])
            : _c(
                "p",
                {
                  staticClass: "interval",
                  style: {
                    "border-bottom":
                      "solid 1px hsl(" + _vm.$attrs.answers + "99deg, 99%, 45%)"
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("QUESTION.NA71.CHOICE." + _vm.$attrs.answers)
                      ) +
                      " "
                  )
                ]
              )
        ])
      : _vm._e(),
    !_vm.units && _vm.$attrs.type !== "valorationChoices"
      ? _c("h1", { staticClass: "text" }, [
          _c("span", [_vm._v(" " + _vm._s(_vm.$attrs.answers) + " ")])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }