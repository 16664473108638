var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.user
    ? _c(
        "div",
        { staticClass: "ProfileView" },
        [
          _c("TransitionFade", [
            _c(
              "div",
              { staticClass: "content" },
              [
                _c(
                  "TransitionSlide",
                  [
                    _c(_vm.componentSelected, {
                      tag: "component",
                      attrs: { user: _vm.user },
                      on: {
                        "change-component": function($event) {
                          return _vm.changeComponent($event)
                        },
                        "change-information-user": function($event) {
                          return _vm.changeInformationUser($event)
                        },
                        "change-password": function($event) {
                          return _vm.changePassword($event)
                        },
                        "change-avatar": function($event) {
                          return _vm.changeAvatar($event)
                        }
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }