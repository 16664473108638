<template>
  <div
    v-if="user"
    class="ProfileView"
  >
    <TransitionFade>
      <div class="content">
        <TransitionSlide>
          <component
            :is="componentSelected"
            :user="user"
            @change-component="changeComponent($event)"
            @change-information-user="changeInformationUser($event)"
            @change-password="changePassword($event)"
            @change-avatar="changeAvatar($event)"
          />
        </TransitionSlide>
      </div>
    </TransitionFade>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';
import ChangePassword from '@/components/profile/ChangePassword';
import InfoUser from '@/components/profile/InfoUser';
import ChangeAvatar from '@/components/profile/ChangeAvatar';

import TransitionSlide from '@/transitions/TransitionSlide';
import TransitionFade from '@/transitions/TransitionFade';

export default {
  components: {
    ChangePassword,
    InfoUser,
    ChangeAvatar,
    TransitionSlide,
    TransitionFade,
  },

  data() {
    return {
      userName: '',
      componentSelected: 'InfoUser',
    };
  },

  computed: {
    ...get('authModule', ['getUser']),

    user() {
      return this.getUser();
    },
    name() {
      return `${this.user.name}` ?? '--';
    },

    language() {
      return `${this.user.locale}` ?? '--';
    },
  },

  methods: {
    changeComponent(payload) {
      this.componentSelected = payload;
    },

    async changeAvatar(payload) {
      const avatar = payload;

      await this.$store.dispatch('authModule/changeAvatar', avatar);
      this.componentSelected = 'infoUser';
    },

    async changeInformationUser(payload) {
      await this.$store.dispatch('authModule/changeInformationUser', payload);

      this.componentSelected = 'InfoUser';
    },

    async changePassword(payload) {
      const data = {
        actualPassword: payload.passwordActual,
        newPassword: payload.password,
      };

      await this.$store.dispatch('authModule/changePassword', data);

      this.componentSelected = 'InfoUser';
    },

  },
};
</script>

<style lang="scss" scoped>
.ProfileView {
  padding-top: $spacing-m;
 /*  background-image: url('~@/assets/images/f_login.jpg'); */
  background-size: cover;
  background-position: center;
  position: relative;
  opacity: 0.8;
  display: flex;
  justify-content: center;
  flex-direction: column;
   /* background-image: linear-gradient(rgba(82, 211, 23, 0.2), rgba(236, 203, 15, 0.5)),
    url('~@/assets/images/spruce.jpg'); */
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    max-width: 1200px;
    margin: auto;
    flex-wrap: wrap;

    box-shadow: $shadow-elevation-2;
    .menu{
      color:#fff;
      display: flex;
      flex-direction: column;
      flex:1;
      margin:$spacing-2xl;

    }


  }
  .changePassword {
    background: white;
  }
}
</style>
