var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ChoiceCustom" }, [
    _c("p", { staticClass: "question" }, [
      _vm._v(
        " " +
          _vm._s(_vm.$t("QUESTION." + _vm.name + ".CHOICE." + _vm.choice)) +
          " "
      )
    ]),
    _vm.subChoice
      ? _c("p", { staticClass: "subchoice" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("QUESTION." + _vm.name + ".CHOICE." + _vm.valueSubChoice)
              ) +
              " "
          )
        ])
      : _vm._e(),
    _vm.subValue !== ""
      ? _c("p", { staticClass: "subvalue" }, [
          _vm._v(" " + _vm._s(_vm.subValue) + " ")
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }