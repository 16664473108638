var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "changePassword" }, [
    _c(
      "div",
      { staticClass: "back" },
      [
        _c("font-awesome-icon", {
          staticClass: "fontAwesome",
          attrs: { icon: ["fas", "arrow-left"] },
          on: {
            click: function($event) {
              return _vm.changeComponent("InfoUser")
            }
          }
        }),
        _vm._v(" " + _vm._s(_vm.$t("PROFILE.PASSWORDCHANGE.BUTTON")) + " ")
      ],
      1
    ),
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "first-column" }, [
        _c(
          "div",
          { staticClass: "icon" },
          [_c("font-awesome-icon", { attrs: { icon: ["fas", "image"] } })],
          1
        )
      ]),
      _c("div", { staticClass: "form" }, [
        _c(
          "p",
          { staticClass: "email" },
          [
            _c("font-awesome-icon", {
              attrs: { icon: ["fas", "envelope"], pull: "right" }
            }),
            _c("span", [_vm._v(_vm._s(_vm.user.email))])
          ],
          1
        ),
        _c(
          "aside",
          [
            _vm.currentImage
              ? _c("div", {
                  staticClass: "avatar",
                  style: { backgroundImage: "url(" + _vm.currentImage + ")" }
                })
              : _c("div", { staticClass: "avatar" }),
            _c("BaseInputFile", {
              attrs: { tag: "image", accept: "image/*" },
              nativeOn: {
                change: function($event) {
                  return _vm.loadImage($event)
                }
              }
            }),
            _c(
              "div",
              { staticClass: "boxButtonAvatar" },
              [
                _c(
                  "BaseButton",
                  {
                    attrs: { size: "s" },
                    on: {
                      click: function($event) {
                        return _vm.changeAvatar()
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("BUTTON.SAVE")) + " ")]
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }