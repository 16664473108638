var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Map" },
    [
      _vm.myCoordinates
        ? _c("div", { staticClass: "my-ubication" }, [
            _c("p", { attrs: { for: "Latitud" } }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("LATITUDE")) +
                  " : " +
                  _vm._s(_vm.myCoordinates.lat) +
                  " "
              )
            ]),
            _c("p", { attrs: { for: "Longitud" } }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("LENGTH")) +
                  " : " +
                  _vm._s(_vm.myCoordinates.lng) +
                  " "
              )
            ])
          ])
        : _vm._e(),
      _vm._t("error"),
      _c(
        "GmapMap",
        {
          ref: "mapRef",
          staticStyle: { width: "100%", height: "350px" },
          attrs: {
            center: _vm.centerMap,
            zoom: _vm.zoom,
            "map-type-id": "satellite"
          }
        },
        [
          _c("GmapMarker", {
            attrs: {
              position: _vm.myCoordinates,
              clickable: true,
              draggable: true
            },
            on: {
              click: function($event) {
                _vm.center = _vm.myCoordinates
              }
            }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }