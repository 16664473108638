<template>
  <div class="Choices">
    <ul>
      <li
        v-for="item in choices"
        :key="item"
      >
        {{ $t(`QUESTION.${name}.CHOICE.${item}`) }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {

  computed: {
    choices() {
      const { answers } = this.$attrs;
      return answers.map(answer => answer.replace('.', '-'));
    },

    name() {
      return this.$attrs.attributes.i18n || this.$attrs.name;
    }
  },
};
</script>

<style lang="scss" scoped >
.Choices {

  ul {
    list-style: none;
    background: $color-neutral-lightest;
    padding: $spacing-m;
    border-radius: 10px;

   // padding: 10px;
  }
  li {
    position: relative;
    padding-left: 1.5em;
  }
  li:before {
    content: '';
    position: absolute;
    left: 0;
    width: 2em;
    height: 2em;
    background: url("data:image/svg+xml;utf8,<?xml version='1.0' encoding='utf-8'?><svg width='18' height='18' viewBox='0 0 1792 1792' xmlns='http://www.w3.org/2000/svg'><path d='M1671 566q0 40-28 68l-724 724-136 136q-28 28-68 28t-68-28l-136-136-362-362q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 295 656-657q28-28 68-28t68 28l136 136q28 28 28 68z'/></svg>")
      no-repeat;
  }
}
</style>
