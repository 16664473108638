<template>
  <div class="Input">
    <h1
      v-if="units"
      class="unidades"
    >
      <span class="units"> {{ $attrs.answers }} {{ units }} </span>
    </h1>
    <h1 v-if="$attrs.type === 'valorationChoices'">
      <span
        v-if="$attrs.attributes.images"
        class="valoration"
      >
        <img
          class="image"
          :src="getImage( $attrs.answers - 1)"
          alt="logo"
        >

        <p class="star">
          <font-awesome-icon
            v-for="star in start($attrs.answers)"
            :key="star"
            :icon="['fas', 'star']"
          />
        </p>
      </span>
      <p
        v-else
        class="interval"
        :style="{'border-bottom': `solid 1px hsl(${$attrs.answers}99deg, 99%, 45%)`}"
      >
        {{ $t(`QUESTION.NA71.CHOICE.${$attrs.answers}`) }}
      </p>
    </h1>

    <h1
      v-if="!units && $attrs.type !== 'valorationChoices'"
      class="text"
    >
      <span> {{ $attrs.answers }}  </span>
    </h1>
  </div>
</template>

<script>
export default {

  computed: {
    units() {
      return this.$attrs?.attributes?.units ?? '';
    },
  },

  methods: {
    getImage(i) {
      const images = this.$attrs?.attributes.images;
      if (!images) return undefined;

      // eslint-disable-next-line
      return require(`@/assets/images/form/${images[i]}`);
    },

    start(item) {
      const number = item;
      // eslint-disable-next-line radix
      const result = parseInt(number);
      return result;
    },

  },
};
</script>

<style lang="scss" scoped>
.Input {
  margin-left: 0.5rem;
  padding: 0.5rem;
  width: 100%;

  .unidades {
    margin-top: -1.8rem;
    text-align: right;
  }

  .units {
    //padding: 0.5rem;
    font-size: 0.8rem;
    padding: 0.5rem;
    background: $color-success;
    color: #fff;
    border-radius: 5px;
  }
  .valoration {
    display: flex;
    justify-content: center;
    align-items: center;
    .image {
      width: 15rem;

      text-align: center;
    }
    .star {
      color: $color-primary;
      margin-top: -1rem;
      text-align: center;
      font-size: $font-size-3xl;
    }
  }

  .interval {
    padding: $spacing-xs;
  }

  .text{
    background: $color-neutral-lightest;
    padding: $spacing-m;

   // border-radius: 10px;
  }
}
</style>
