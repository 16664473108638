<template>
  <div class="ChoiceCustom">
    <p class="question">
      {{ $t(`QUESTION.${name}.CHOICE.${choice}`) }}
    </p>

    <p
      v-if="subChoice"
      class="subchoice"
    >
      {{ $t(`QUESTION.${name}.CHOICE.${valueSubChoice}`) }}
    </p>

    <p
      v-if="subValue!== ''"
      class="subvalue"
    >
      {{ subValue }}
    </p>
  </div>
</template>

<script>
export default {

  computed:
  {
    choice() {
      const choice = this.$attrs.answers.value;
      return choice.substring(0, 1);
    },

    subChoice() {
      const choice = this.$attrs.answers.value;
      if (choice.length > 1) {
        return true;
      }
      return false;
    },

    valueSubChoice() {
      const choice = this.$attrs.answers.value;
      if (choice.length > 1) {
        return choice.replace('.', '-');
      }
      return choice;
    },

    subValue() {
      return this.$attrs.answers?.subvalue ?? '';
    },

    name() {
      return this.$attrs.attributes.i18n || this.$attrs.name;
    }
  },

};
</script>

<style lang="scss" scoped>
.ChoiceCustom {
    background: $color-neutral-lightest;
    padding: $spacing-m;
  .question {
    padding: 0.2rem;
    margin-left: 0.5rem;
  }
  .subchoice {
    margin-left: 1rem;
    padding: 0.2rem;
  }

  .subvalue {
    margin-left: 1.5rem;
    padding: 0.2rem;
  }
}
</style>
